<template lang='pug'>
  .summernote-wrapper
    .note-editor
      .note-toolbar.btn-toolbar
        .btn-group(v-for='control in controls')
          button.btn.btn-default.btn-sm.btn-small(
          :class='{"dropdown-toggle": control.items, "button-highlight": control.highlight}'
          @click='callAction(control.action)'
          :data-toggle='control.items ? "dropdown" : ""')

            i.fa(:class='control.icon')
            span.caret(v-if='control.items')
          ul.dropdown-menu(v-if='control.items')
            li(v-for='e in control.items')
              a(:class='{"checked": e.spaces === tabSettings.spaces}' @click='callAction(e.action)')
                i.fa.fa-check.icon-ok
                | {{ e.label }}

      .editor.input(ref='editor')
      //.note-statusbar
      //  .note-resizebar
      //    .note-icon-bar
      //    .note-icon-bar
      //    .note-icon-bar

</template>

<script>
  import FileSaver from 'file-saver'
  import YAML from 'js-yaml'
  import ace from 'ace-builds/src-noconflict/ace'
  import theme from 'ace-builds/src-noconflict/theme-dawn'

  const CDN = 'https://cdn.jsdelivr.net/npm/ace-builds@1.4.1/src-min-noconflict'
  ace.config.set('basePath', CDN)
  ace.config.set('modePath', CDN)
  ace.config.set('themePath', CDN)
  ace.config.set('workerPath', CDN)

  const controls = [
    {
      label: 'Indent',
      icon: 'fa-indent icon-indent-right',
      items: [
        {label: '1 Tab', tab: 1, spaces: 0, action: {'func': 'setTabs', args: {tab: 1, spaces: 0}}},
        {label: '1 Space', tab: 0, spaces: 1, action: {'func': 'setTabs', args: {tab: 0, spaces: 1}}},
        {label: '2 Spaces', tab: 0, spaces: 2, action: {'func': 'setTabs', args: {tab: 0, spaces: 2}}},
        {label: '3 Spaces', tab: 0, spaces: 3, action: {'func': 'setTabs', args: {tab: 0, spaces: 3}}},
        {label: '4 Spaces', tab: 0, spaces: 4, action: {'func': 'setTabs', args: {tab: 0, spaces: 4}}},
        {label: '5 Spaces', tab: 0, spaces: 5, action: {'func': 'setTabs', args: {tab: 0, spaces: 5}}},
        {label: '6 Spaces', tab: 0, spaces: 6, action: {'func': 'setTabs', args: {tab: 0, spaces: 6}}},
      ]
    }, {
      label: 'Reformat',
      icon: 'fa-magic icon-magic',
      action: {func: 'format', args: null}
    }, {
      label: 'Download',
      icon: 'fa-download icon-download',
      highlight: true,
      action: {func: 'downloadResult', args: null}
    }
  ]

  export default {
    name: 'CodeEditor',
    mounted() {
      const self = this

      this.$refs.editor.style.height = this.height

      this.editor = ace.edit(this.$refs.editor, {readOnly: this.readOnly})
      this.editor.setTheme(theme)
      this.editor.session.setMode(`ace/mode/${this.mode}`)

      this.editor.on('paste', function () {
        self.$parent.$emit(
          'editorContentPaste',
          {name: self.name, value: self.editor.getValue(), editor: self.editor}
        )
      })

      this.editor.session.on('change', function () {
        if (self.isEditing) return

        self.isEditing = true
        self.content = self.editor.getValue()

        self.$parent.$emit(
          'editorContentChanged',
          {name: self.name, value: self.editor.getValue(), editor: self.editor}
        )

        self.isEditing = false
      })
    },
    props: {
      mode: {default: 'json'},
      name: {default: 'editor'},
      mimeType: {deftault: 'application/json'},
      fileExt: {default: 'json'},
      readOnly: {default: false},
      height: {default: '650px'},
      controls: {default: function () { return controls}},

    },
    data() {
      return {
        content: '',
        isEditing: false,
        tabSettings: {label: '2 Spaces', tab: 0, spaces: 2}
      }
    },
    methods: {
      callAction(action) {
        if (!action) return
        this[action.func](action.args)
      },
      setContent(content) {
        const cursor = this.editor.getCursorPosition()
        this.content = content
        this.editor.setValue(content)
        this.editor.gotoLine(cursor.row + 1, cursor.column)
      },
      format(type) {
        type = type === null ? this.mode : type

        if (type === 'json') {
          this.formatJSON()
        } else if (type === 'yaml') {
          this.formatYAML()
        }
      },
      formatJSON() {
        if (!this.content) return
        this.isEditing = true

        const jObj = JSON.parse(this.content)
        const indent = this.tabSettings.spaces
        const jString = JSON.stringify(jObj, null, indent)
        this.setContent(jString)

        this.isEditing = false
      },
      formatYAML() {
        if (!this.content) return
        this.isEditing = true

        const yObj = YAML.safeLoad(this.content)
        const indent = this.tabSettings.spaces
        const yString = YAML.safeDump(yObj, {indent: indent})
        this.setContent(yString)

        this.isEditing = false
      },
      setTabs(c) {
        this.tabSettings = c

        if (this.mode === 'json' || this.mode === 'yaml') {
          this.format(this.mode)
        }
      },
      downloadResult() {
        const res = this.editor.getValue()
        const blob = new Blob([res], {type: `${this.mimeType};charset=utf-8`})
        FileSaver.saveAs(blob, `result.${this.fileExt}`)
        this.$parent.$parent.$emit(
          'pgNotification',
          {message: 'Download Successfull', type: 'success'}
        )

        this.$ga.event({eventCategory: 'editor', eventAction: 'download', eventLabel: 'file'})
      }
    }
  }

</script>

<style lang="scss">
  @import "../assets/plugins/summernote/css/summernote.css";

  .summernote-wrapper {
    .note-editor {
      .note-editable {
        padding: 0;
      }

      .note-toolbar {
        .btn-group {
          button.btn.button-highlight {
            background-color: #6d5cae;
            color: white;
          }
        }

        .dropdown-menu {
          li {
            a {
              line-height: 35px;
              cursor: pointer;

              i {
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
  }

  .editor, .example-editor {
    width: 100%;
  }

</style>
